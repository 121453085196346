import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RestService {
    constructor(http) {
        this.http = http;
    }
    setHeaders(headers) {
        const defaultHeaders = {
            'Content-Type': 'application/json',
        };
        const newHeaders = Object.assign(defaultHeaders, headers);
        const httpOptions = {
            headers: new HttpHeaders(newHeaders),
        };
        return httpOptions;
    }
    // private setParams(params: any) {
    //   const defaultParams = {};
    //   const newParams = Object.assign(defaultParams, params);
    //   const httpParams = {
    //     params: new HttpParams(newParams),
    //   };
    //   return httpParams;
    // }
    setOptions(options) {
        const defaultOptions = Object.assign({}, this.setHeaders(options && options.headers), (options && options.params && { params: options.params }), (options && options.reportProgress && { reportProgress: true }), (options && options.observe && { observe: options.observe }), (options &&
            options.responseType && { responseType: options.responseType }), (options &&
            options.withCredentials && {
            withCredentials: options.withCredentials,
        }));
        return defaultOptions;
    }
    // GET
    get(apiURL, options) {
        return this.http
            .get(apiURL, Object.assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    }
    // POST
    post(apiURL, req, options) {
        return this.http
            .post(apiURL, req, Object.assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    }
    // PUT
    put(apiURL, req, options) {
        return this.http
            .put(apiURL, req, Object.assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    }
    // PATCH
    patch(apiURL, req, options) {
        return this.http
            .patch(apiURL, req, Object.assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    }
    // DELETE
    delete(apiURL, options) {
        return this.http
            .delete(apiURL, Object.assign({}, this.setOptions(options)))
            .pipe(catchError(this.errorHandl));
    }
    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = error;
        }
        return throwError(errorMessage);
    }
}
RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient)); }, token: RestService, providedIn: "root" });
