import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { ToastController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from '../environments/environment';
import { DataService, } from '@shared/services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "@ionic/storage";
import * as i4 from "../app/shared/services/data.service";
export class ApiService {
    constructor(http, toastController, loadingController, storage, dataService // private storageService: StorageService
    ) {
        this.http = http;
        this.toastController = toastController;
        this.loadingController = loadingController;
        this.storage = storage;
        this.dataService = dataService;
        this.apiUrl = environment.apiUrl;
        this.awsUrl = environment.awsUrl;
    }
    show(msg, spinner) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.loading) {
                this.loading = yield this.loadingController.create({
                    message: msg ? msg : '',
                    spinner: spinner ? spinner : 'bubbles',
                });
            }
            yield this.loading.present();
        });
    }
    hide() {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = '';
        }
    }
    appLoading(e, msg, spinner) {
        if (e == true) {
            this.show(msg, spinner);
        }
        if (e == false) {
            this.hide();
        }
    }
    createToast(msg, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.toast.dismiss();
            }
            catch (e) { }
            this.toast = yield this.toastController.create({
                message: msg,
                duration: 2000,
                color: type ? type : 'success',
            });
            this.toast.present();
        });
    }
    // PHP APIs
    saveContract(data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let main1 = {
                mprn_mpan: data.mpan,
                meter_serial_number: data.serial1,
                mpan_top_line: data.top1,
                utility_type_id: 1,
                hugo_user_id: yield this.storage.get("uids" /* UID */),
                supplier_id: data.supplierElectricity,
            };
            let hid = yield this.storage.get("household_id" /* HOUSEHOLD_ID */);
            this.http
                .post(this.apiUrl + 'meter/create', JSON.stringify(main1), {
                params: new HttpParams().set('household_id', hid),
            })
                .subscribe((res) => {
                let obj = { res: res };
                resolve({ res: res });
                if (obj.res.id) {
                    this.storage.set("meterid_electricity" /* METER_ID */, obj.res.id);
                }
            }, (err) => {
                reject(err);
            });
        }));
    }
    updateContract(data, meterId, gasId) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let hid = yield this.storage.get("household_id" /* HOUSEHOLD_ID */);
            let main1 = {
                mprn_mpan: data.mpan,
                meter_serial_number: data.serial1,
                mpan_top_line: data.top1,
                utilty_type_id: 1,
                supplier_id: data.supplierElectricity,
                household_id: hid,
            };
            this.http
                .post(this.awsUrl + 'meter/update?meter_id=' + meterId, JSON.stringify(main1))
                .subscribe((ress) => {
                resolve({ res1: ress, res2: ress });
            }, (err) => {
                reject(err);
            });
        }));
    }
    consent(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const meterId = yield this.storage.get("meterid_electricity" /* METER_ID */);
            if (meterId) {
                return this.http.get(`${this.awsUrl}consent/${type}?meter_id=${meterId}`, {
                    responseType: 'text',
                });
            }
        });
    }
    getSession(id) {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.awsUrl + 'consent/get-session-id?meter_id=' + id)
                .subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }
    getMeters(id) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.http
                .get(this.awsUrl +
                'household/get-meters?id=' +
                (yield this.storage.get("household_id" /* HOUSEHOLD_ID */)))
                .subscribe((data) => {
                data.map((value) => {
                    if (value && value.utility_type_id === 1) {
                        this.storage.set("meterid_electricity" /* METER_ID */, value.id);
                    }
                });
                resolve(data);
            }, (err) => {
                reject(err);
            });
        }));
    }
    getAddress(id) {
        return new Promise((resolve, reject) => {
            this.http.get(this.awsUrl + 'household?id=' + id).subscribe((data) => {
                if (data && data.postcode) {
                    this.storage.set("postcode" /* POSTCODE */, data.postcode);
                }
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }
    loadApi() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const postcode = yield this.storage.get("postcode" /* POSTCODE */);
            if (postcode) {
                return;
            }
            const householdId = yield this.storage.get("household_id" /* HOUSEHOLD_ID */);
            this.getAddress(householdId).then((res) => { });
        });
    }
    updateSuppliers(userId, supplierIdElec, supplierIdGas) {
        const obj = {
            user_id: userId,
            elec_supplier: supplierIdElec,
            gas_supplier: supplierIdGas,
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(this.awsUrl + 'meter/set-suppliers-by-user', JSON.stringify(obj), {})
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                //
                reject(err);
            });
        });
    }
    /* ========================================================== Python Apis ====================================================== */
    getAddress2(e) {
        return new Promise((resolve, reject) => {
            this.http.get(this.awsUrl + 'address?postcode=' + e).subscribe((res) => {
                resolve(res);
            }, (err) => {
                //
                reject(err);
            });
        });
    }
    getSupplier(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let obj = {
                type: e,
            };
            let supplier = this.awsUrl +
                'suppliers?filter=' +
                obj.type +
                '&email=' +
                (yield this.storage.get("email" /* EMAIL */));
            const httpOptions = {
                headers: new HttpHeaders({
                    'x-api-key': 'watfNqunXL3ssZq8xATRx81YKRMmbjll2QxE0Nmz',
                }),
            };
            return new Promise((resolve, reject) => {
                this.http.get(supplier, httpOptions).subscribe((data) => {
                    resolve(data);
                }, (err) => {
                    reject(err);
                });
            });
        });
    }
    getCO2Consumption(consumptionBy, meterID, startDate, endDate, unit_type = 'consumption_units') {
        // calls API when user requests consumption by day, month, hours
        // requires meterID, startDate and endDate in yyyy-mm-dd format
        // guarantees a promise with usage data
        return new Promise((resolve, reject) => {
            this.http
                .get(this.awsUrl + 'co2?', {
                //headers: new HttpHeaders().set('Authorization', 'my-auth-token'),
                params: new HttpParams()
                    .set('meter_id', meterID)
                    // .set('user_id', await this.storage.get(StorageKeys.UID))
                    .set('date_from', startDate)
                    .set('date_to', endDate)
                    .set('unit_type', unit_type)
                    .set('type', consumptionBy),
            })
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    // Consumption -->
    // <!-- User Profiles
    getUserDetails() {
        return new Promise((resolve, reject) => {
            this.http.get(this.awsUrl + 'profiles').subscribe((res) => {
                this.dataService.setUserDetails(res);
                if (res) {
                    if (res.meter) {
                        const meter = res.meter;
                        this.storage.set("mpan" /* MPAN */, meter.meter_number);
                        this.storage.set("contract" /* CONTRACT */, meter.consent_status);
                        this.storage.set("postcode" /* POSTCODE */, meter.postcode);
                        if (meter.verification_method) {
                            this.storage.set("verification_method" /* VERIFICATION_METHOD */, meter.verification_method);
                        }
                        else {
                            this.storage.remove("verification_method" /* VERIFICATION_METHOD */);
                        }
                    }
                }
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    updateUserDetails(data) {
        return new Promise((resolve, reject) => {
            this.http.put(this.awsUrl + 'profiles', JSON.stringify(data)).subscribe((res) => {
                resolve(res);
            }, (err) => {
                //
                reject(err);
            });
        });
    }
    //  -->
    // <!-- Security
    signIn(data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.awsUrl + 'sign-in', JSON.stringify(data)).subscribe((res) => {
                resolve(res);
                return res;
            }, (err) => {
                reject(err);
            });
        });
    }
    signUp(data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.awsUrl + 'sign-up', JSON.stringify(data)).subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    signOut() {
        return new Promise((resolve, reject) => {
            // this.http.delete(this.awsUrl + 'sign-out?deviceId=' + deviceId).subscribe(
            this.http.delete(this.awsUrl + 'sign-out').subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    updateAddress(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.awsUrl + 'update-meter-address', JSON.stringify(data))
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    changePassword(data) {
        return new Promise((resolve, reject) => {
            this.http
                .put(this.awsUrl + 'change-password', JSON.stringify(data))
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                //
                reject(err);
            });
        });
    }
    forgotPassword(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.awsUrl + 'forgot-password', JSON.stringify(data))
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    // Security -->
    // <!-- Email Verify
    verifyEmail(token, email, req) {
        return new Promise((resolve, reject) => {
            this.http
                .patch(`${this.awsUrl}verification?token=${token}&email=${email}`, req)
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    isEmailVerifed(token, email) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.awsUrl}verification?token=${token}&email=${email}`)
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    // Email Verify -->
    // <!-- Suggested Supplier
    getSuggestedSuppliers() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.http
                .get(this.awsUrl +
                'supplier_consumption?email=' +
                (yield this.storage.get("email" /* EMAIL */)))
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        }));
    }
    // Suggested Supplier -->
    updateUserMeter(token, email, req) {
        return this.http
            .patch(`${this.awsUrl}user-meter?token=${token}&email=${email}`, req)
            .toPromise();
    }
    saveCompletedSignupSteps(token, email, req) {
        return this.http
            .patch(`${this.awsUrl}verification-flow?token=${token}&email=${email}`, req)
            .toPromise();
    }
    getVerifiedAddress(token, email) {
        return this.http
            .get(`${this.awsUrl}address-verification?token=${token}&email=${email}`)
            .toPromise();
    }
    verifyAddress(token, email, req) {
        return this.http
            .patch(`${this.awsUrl}address-verification?token=${token}&email=${email}`, req)
            .toPromise();
    }
    // Single Utility
    updateUtilityConnection(req) {
        return this.http.put(`${this.awsUrl}update-utility-connection`, req);
    }
    // Search Address
    getAddressByPostcode(params) {
        return this.http.get(`${this.awsUrl}search-address`, {
            params: Object.assign({ customer_type: 'dom' }, params),
        });
    }
    getMPANDetails(params) {
        return this.http.get(`${this.awsUrl}get-mpan`, { params });
    }
    // Find MPxN
    findMpxn(mpxn) {
        return this.http.get(`${this.awsUrl}find-mpxn?mprn_mpan=${mpxn}`);
    }
    // Manual address
    saveAddress(request) {
        return this.http.post(`${this.awsUrl}add_manual_address`, request);
    }
    // upload utility bill
    uploadUtilityBill(request) {
        return this.http.post(`${this.awsUrl}upload_bills`, request);
    }
    insertUtilityBill(request) {
        return this.http.post(`${this.awsUrl}insert_uploaded_bills`, request);
    }
    updateBillConsent() {
        return this.http.put(`${this.awsUrl}update-bill-consent`, {});
    }
    // credit points
    creditPoints(req) {
        return this.http.post(`${this.awsUrl}credit-points`, req);
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastController), i0.ɵɵinject(i2.LoadingController), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.DataService)); }, token: ApiService, providedIn: "root" });
