import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import supportsWebP from 'supports-webp';
export class SupportsWebpDirective {
    constructor(el) {
        this.el = el;
        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.el.nativeElement.classList.add((yield supportsWebP) ? 'webp' : 'no-webp');
        }), 0);
    }
}
