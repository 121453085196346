import * as tslib_1 from "tslib";
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class ToastService {
    constructor(toastController) {
        this.toastController = toastController;
    }
    /**
     * Present toast alert.
     * @param message - Toastr message.
     * @param color - Toastr type, default is success.
     */
    presentToast(message, color = 'success') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                duration: 3500,
                color,
            });
            toast.present();
        });
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.ɵɵinject(i1.ToastController)); }, token: ToastService, providedIn: "root" });
