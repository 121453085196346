import { RestService } from './rest.service';
import { ToastService } from './toast.service';
import { FileExtentions } from '../models/enums';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./toast.service";
export class FileUploadService {
    constructor(restService, toastService) {
        this.restService = restService;
        this.toastService = toastService;
    }
    setHeaders(options) {
        const headers = {
            'Content-Type': 'application/binary',
        };
        const newHeaders = Object.assign(headers, options);
        return { headers: newHeaders };
    }
    uploadFileOnSignedUrl(url, file) {
        return this.restService.put(`${url}`, file, Object.assign({}, this.setHeaders({ 'Content-Type': file.type }), { reportProgress: true, observe: 'events' }));
    }
    // Utils
    getExtension(fileName) {
        const parts = fileName.split('.');
        return parts[parts.length - 1].toLowerCase();
    }
    validateFileExtension(fileName, supportedFileExtensions, toastMessage) {
        const extensions = supportedFileExtensions && supportedFileExtensions.length
            ? supportedFileExtensions
            : [
                FileExtentions.JPEG,
                FileExtentions.JPG,
                FileExtentions.PNG,
                FileExtentions.PDF,
            ];
        if (fileName && !extensions.includes(this.getExtension(fileName))) {
            this.toastService.presentToast(toastMessage ||
                `File type not supported please select .${extensions.join(', .')}`, 'danger');
            return false;
        }
        return true;
    }
}
FileUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i2.ToastService)); }, token: FileUploadService, providedIn: "root" });
