import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnChanges, } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DataService } from '@shared/services';
import { AuthService } from '@providers/auth.service';
import { APP_STORE_URL, PLAY_STORE_URL } from '@app/config';
import { environment } from '@environments/environment';
const DOMAIN = environment.websiteUrl;
export class HeaderComponent {
    constructor(router, navCtrl, cdr, storage, dataService, authService) {
        this.router = router;
        this.navCtrl = navCtrl;
        this.cdr = cdr;
        this.storage = storage;
        this.dataService = dataService;
        this.authService = authService;
        this._showHeader = false;
        this._showBackButton = false;
        this._defaultBack = false;
        this.consentStatus = false;
        this.menus = [
            {
                label: 'Features',
                url: `${DOMAIN}/#features`,
                type: 'web-target',
                isVisible: true,
            },
            {
                label: 'Media',
                type: 'dropdown',
                isVisible: true,
                subMenus: [
                    {
                        label: 'Press',
                        url: `${DOMAIN}/topics/press/`,
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'Blog',
                        url: `${DOMAIN}/topics/blog/`,
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'Videos',
                        url: `${DOMAIN}/topics/meet-hugo/`,
                        type: 'web-link',
                        isVisible: true,
                    },
                ],
            },
            {
                label: 'Ask Hugo',
                type: 'dropdown',
                isVisible: true,
                subMenus: [
                    {
                        label: 'Virtual Assistant',
                        url: `${DOMAIN}`,
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'FAQs',
                        url: `${DOMAIN}/faqs/`,
                        type: 'web-link',
                        isVisible: true,
                    },
                    {
                        label: 'Contact Us',
                        url: `${DOMAIN}/contact-us/`,
                        type: 'web-link',
                        isVisible: true,
                    },
                ],
            },
            {
                label: 'Offset Co2',
                url: `/dashboard/co2/select-location`,
                type: 'app-link',
                isVisible: true,
            },
        ];
        this.domain = `${DOMAIN}/`;
        this.isLoggedIn = false;
        this.isVisible = {
            getHugo: true,
            mobileMenu: false,
        };
        this.appStoreUrl = APP_STORE_URL;
        this.playStoreUrl = PLAY_STORE_URL;
    }
    set showHeader(showHeader) {
        this._showHeader = showHeader;
    }
    get showHeader() {
        return this._showHeader;
    }
    set showBackButton(showBackButton) {
        this._showBackButton = showBackButton;
    }
    get showBackButton() {
        return this._showBackButton;
    }
    set defaultBack(value) {
        this._defaultBack = value;
    }
    get defaultback() {
        return this._defaultBack;
    }
    ngOnInit() {
        this.dataService.getConsentStatus().subscribe((status) => {
            this.consentStatus = status;
        });
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.url = e.url;
                let url = e.url;
                if (url.indexOf('#') >= 0) {
                    url = url.split('#')[0];
                }
                url = url.split('/');
                url.splice(0, 1);
                this.navigateToGetHugoSection = `${url.join('-')}-get-hugo-section`;
                this.isVisible.getHugo = e.url.includes('co2') ? false : true;
                this.storage.get("authToken" /* AUTH_TOKEN */).then((token) => {
                    this.isLoggedIn = token ? true : false;
                    for (const menu of this.menus) {
                        if (menu.label.toLowerCase() === 'offset co2') {
                            menu.isVisible = this.isLoggedIn ? false : true;
                            // menu.isVisible = e.url.includes('co2') ? false : true;
                        }
                    }
                });
            }
        });
    }
    ngOnChanges() {
        this.checkConsentStatus();
        this.cdr.detectChanges();
    }
    toggleMobileMenu() {
        this.isVisible.mobileMenu = !this.isVisible.mobileMenu;
        // to close mobile menu if it is open
        const subMenuTriggers = document.querySelectorAll('.mobile-nav .submenu-trigger');
        if (subMenuTriggers && subMenuTriggers.length) {
            subMenuTriggers.forEach((el) => {
                if (el.nextElementSibling) {
                    el.nextElementSibling.classList.remove('show');
                }
            });
        }
    }
    closeMobileMenu() {
        this.isVisible.mobileMenu = false;
    }
    toggleMobileSubMenu(e) {
        const subMenuTriggers = document.querySelectorAll('.mobile-nav .submenu-trigger');
        const nextElSibClass = e.target.nextElementSibling.classList;
        if (nextElSibClass.contains('show')) {
            nextElSibClass.remove('show');
        }
        else {
            if (subMenuTriggers && subMenuTriggers.length) {
                subMenuTriggers.forEach((el) => {
                    if (el.nextElementSibling) {
                        el.nextElementSibling.classList.remove('show');
                    }
                });
                nextElSibClass.add('show');
            }
        }
    }
    navigateToGetHugo(url, section) {
        window.location.hash = section;
    }
    checkConsentStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const contract = yield this.storage.get("contract" /* CONTRACT */);
            this.dataService.setConsentStatus(contract === 'false' ? false : true);
        });
    }
    goBack() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this._defaultBack) {
                this.navCtrl.navigateBack([this._defaultBack.toString()]);
            }
            else {
                const tabPage = yield this.storage.get("tabPage" /* TAB_PAGE */);
                if (tabPage) {
                    this.navCtrl.navigateBack([`/dashboard/${tabPage}`]);
                }
                else {
                    this.navCtrl.back();
                }
            }
        });
    }
}
