import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ionic/storage";
export class AuthGuard {
    constructor(router, storage) {
        this.router = router;
        this.storage = storage;
    }
    canActivate(next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.storage.get("uids" /* UID */);
            if (uid) {
                // this.router.navigate(['/dashboard/account-overview']);
                return true;
            }
            else {
                this.router.navigate(['/home']);
                return false;
            }
        });
    }
    canActivateChild(route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (state.url.split('/').includes('co2')) {
                return true;
            }
            const uid = yield this.storage.get("uids" /* UID */);
            if (!uid) {
                this.router.navigate(['/home']);
                return false;
            }
            return true;
        });
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Storage)); }, token: AuthGuard, providedIn: "root" });
