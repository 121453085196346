import { RestService } from './rest.service';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
const { baseUrl, geoPlugin, proxyUrl } = environment;
export class GeoPluginService {
    constructor(restService) {
        this.restService = restService;
    }
    geoLocation() {
        // `${baseUrl}/geo-plugin`
        // return this.restService.get(`${geoPlugin}`);
        return this.restService.get(`${proxyUrl}/http://ip-api.com/json/?fields=countryCode`);
    }
}
GeoPluginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeoPluginService_Factory() { return new GeoPluginService(i0.ɵɵinject(i1.RestService)); }, token: GeoPluginService, providedIn: "root" });
