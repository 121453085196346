import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class LoaderService {
    constructor(loadingController) {
        this.loadingController = loadingController;
        this.isLoading = false;
        this.loader = null;
    }
    present(message = '', spinner = 'bubbles') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            return yield this.loadingController
                .create({
                // duration: 5000,
                message,
                spinner,
            })
                .then((load) => {
                this.loader = load;
                this.loader.present().then(() => {
                    if (!this.isLoading) {
                        this.loader.dismiss().then(() => { });
                    }
                });
            });
        });
    }
    dismiss() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = false;
            return yield this.loadingController.dismiss().then(() => { });
        });
    }
    appLoading(e, msg, spinner) {
        e ? this.present(msg, spinner) : this.dismiss();
    }
    updateLoaderMessage(msg) {
        if (this.loader && this.loader.message) {
            this.loader.message = msg;
        }
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.ɵɵinject(i1.LoadingController)); }, token: LoaderService, providedIn: "root" });
