import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertController, NavController, Platform, ActionSheetController, PopoverController, ToastController, ModalController, PickerController, } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { mergeMap, map, filter } from 'rxjs/operators';
import { ApiService } from '@providers/api.service';
import { 
// StorageService,
RedirectionService, } from '@shared/services';
import { DEFAULT_CURRENCY_USER } from './config';
import { environment } from '@environments/environment';
export class AppComponent {
    // showSplash = true;
    constructor(platform, router, titleService, activeRoute, apiProvider, navCtrl, alertController, modalCtrl, actionSheetController, popoverController, toastController, pickerController, storage, 
    // private storageService: StorageService,
    redirectionService // private dataService: DataService, // private geoPluginService: GeoPluginService
    ) {
        this.platform = platform;
        this.router = router;
        this.titleService = titleService;
        this.activeRoute = activeRoute;
        this.apiProvider = apiProvider;
        this.navCtrl = navCtrl;
        this.alertController = alertController;
        this.modalCtrl = modalCtrl;
        this.actionSheetController = actionSheetController;
        this.popoverController = popoverController;
        this.toastController = toastController;
        this.pickerController = pickerController;
        this.storage = storage;
        this.redirectionService = redirectionService;
        this.isDeepLink = false;
        this.networkStatus = false;
        this.networkStatus$ = Subscription.EMPTY;
        this.showNoNetworkContent = false;
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
            .pipe(map(() => navigator.onLine))
            .subscribe((status) => {
            this.networkStatus = status;
            if (this.networkStatus === false) {
                this.showNoNetworkContent = true;
            }
            else {
                // We just got a connection but we need to wait briefly
                // before we determine the connection type. Might need to wait.
                // prior to doing any api requests as well.
                setTimeout(() => {
                    this.showNoNetworkContent = false;
                }, 3000);
            }
        });
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.household = yield this.storage.get("household_id" /* HOUSEHOLD_ID */);
            // setting default tabpage to usage for fixing unable to go to root page from profile inner screens
            this.storage.set("tabPage" /* TAB_PAGE */, 'dashboard/account-overview');
            // this.dataService.setStorageData({ [StorageKeys.TAB_PAGE]: 'co2' });
            // setting default currency to gbp
            const defaultCurrencyUser = localStorage.getItem('defaultCurrencyUser');
            if (!defaultCurrencyUser) {
                localStorage.setItem('defaultCurrencyUser', DEFAULT_CURRENCY_USER);
            }
            this.initializeApp();
            const uid = yield this.storage.get("uids" /* UID */);
            const address = yield this.storage.get("address" /* ADDRESS */);
            if (uid && address != 'false') {
                this.apiProvider.getUserDetails().then((uD) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const { meter: { meter_number, consent_status }, verification_step, email_verified, is_signup_with_utility_bill, } = uD;
                    if (consent_status === false) {
                        if (verification_step == 4) {
                            if (is_signup_with_utility_bill) {
                                this.router.navigate(['/signup/steps/verify-utility-bill'], {
                                    replaceUrl: true,
                                    queryParams: {
                                        screen: 'holding',
                                    },
                                });
                            }
                            else {
                                this.router.navigate(['/signup/steps/add-meter'], {
                                    replaceUrl: true,
                                });
                            }
                        }
                        else if (verification_step == null) {
                            if (email_verified) {
                                this.router.navigate(['/signup/steps'], {
                                    replaceUrl: true,
                                });
                            }
                            else {
                                this.router.navigate(['/verify-email'], {
                                    replaceUrl: true,
                                });
                            }
                        }
                        else if (verification_step <= 3) {
                            this.redirectTo(verification_step, is_signup_with_utility_bill);
                        }
                        else {
                            // passing 5 for redirecting to usage
                            this.redirectTo(5);
                        }
                    }
                    // else if (consent_status) {
                    //   // passing 5 for redirecting to usage
                    //   this.redirectTo(5);
                    // }
                    else {
                        if (!this.isDeepLink) {
                            const pageName = yield this.storage.get("redirectTo" /* REDIRECT_TO */);
                            // const queryParams = this.storage.set(StorageKeys.QUERY_PARAMS);
                            if (pageName) {
                                this.storage.remove("redirectTo" /* REDIRECT_TO */);
                                this.storage.remove("notificationId" /* NOTIFICATION_ID */);
                                // this.storage.remove(StorageKeys.QUERY_PARAMS);
                                this.redirectionService.redirectTo(pageName, '');
                            }
                            else {
                                this.router
                                    .navigate(['/dashboard/account-overview'], {
                                    replaceUrl: true,
                                })
                                    .then(() => { });
                            }
                        }
                    }
                }));
            }
        }))();
    }
    ionViewWillLeave() {
        this.disconnectSubscription.unsubscribe();
        this.connectSubscription.unsubscribe();
    }
    ngOnDestroy() {
        if (this.networkStatus$) {
            this.networkStatus$.unsubscribe();
        }
    }
    redirectTo(step, isSignupWithUtilityBill) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const verificationMethod = yield this.storage.get("verification_method" /* VERIFICATION_METHOD */);
            const isMpanInvalid = yield this.storage.get("isMpanInvalid" /* IS_MPAN_INVALID */);
            switch (step) {
                case 1:
                    this.router.navigate(['/signup/steps/add-meter']);
                    break;
                case 2:
                    // this.router.navigate(['/signup/steps/terms-and-conditions']);
                    this.router.navigate([
                        // verificationMethod == 'IHD'
                        isMpanInvalid === true || isMpanInvalid === 'true'
                            ? '/signup/steps/unable-to-find'
                            : '/signup/steps/terms-and-conditions',
                    ]);
                    break;
                case 3:
                    this.router.navigate([
                        isSignupWithUtilityBill
                            ? '/signup/steps/verify-utility-bill'
                            : '/signup/steps/confirm-your-right',
                    ], Object.assign({}, (isSignupWithUtilityBill && {
                        queryParams: {
                            screen: 'holding',
                        },
                    })));
                    break;
                default:
                    this.router
                        .navigate(['/dashboard/account-overview'], { replaceUrl: true })
                        .then(() => { });
                    break;
            }
        });
    }
    initializeApp() {
        this.platform.ready().then(() => {
            window.addEventListener('load', () => {
                (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', environment.gtm.id);
                (function (id) {
                    const s = document.createElement('script');
                    s.async = true;
                    s.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
                    document.head.prepend(s);
                })(environment.gtag.id);
            });
            // setTimeout(() => (this.showSplash = false), 2500);
            // this.geoPluginService.geoLocation().subscribe({
            //   next: (res) => {
            //     this.dataService.setGeoLocationData(res);
            //   },
            //   error: (err) => {},
            // });
        });
    }
    presentMaintenanceAlert(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                cssClass: 'cst-alert-dialog cst-alert-dialog--app-maintenance',
                header: data.title,
                message: data.message,
                backdropDismiss: false,
                mode: 'ios',
            });
            yield alert.present();
        });
    }
    ngOnInit() {
        this.subscribeToRouteChangeEvents();
    }
    setTitleFromRouteData(routeData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = yield this.storage.get("authToken" /* AUTH_TOKEN */);
            if (routeData) {
                const { showHeader, showBackButton, defaultBack } = routeData;
                this.showHeader = showHeader !== undefined ? showHeader : false;
                this.showBackButton =
                    showBackButton !== undefined
                        ? token
                            ? showBackButton
                            : this.router.url.includes('select-location') ||
                                this.router.url.includes('co2/message')
                                ? false
                                : showBackButton
                        : false;
                this.defaultBack = defaultBack !== undefined ? defaultBack : false;
            }
        });
    }
    getLatestChild(route) {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
    subscribeToRouteChangeEvents() {
        // Set initial title
        const latestRoute = this.getLatestChild(this.activeRoute);
        if (latestRoute) {
            this.setTitleFromRouteData(latestRoute.data.getValue());
        }
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd), map(() => this.activeRoute), map((route) => this.getLatestChild(route)), filter((route) => route.outlet === 'primary'), mergeMap((route) => route.data))
            .subscribe((event) => {
            // get and set default currency
            const defaultCurrencyUser = localStorage.getItem('defaultCurrencyUser');
            if (!defaultCurrencyUser) {
                localStorage.setItem('defaultCurrencyUser', DEFAULT_CURRENCY_USER);
            }
            else {
                localStorage.setItem('defaultCurrencyUser', defaultCurrencyUser);
            }
            this.setTitleFromRouteData(event);
        });
    }
}
