import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Subject } from 'rxjs';
import { createTextMaskInputElement } from 'text-mask-core';
export class IonInputMaskDirective {
    constructor(ionInput) {
        this.ionInput = ionInput;
        this.mask = [];
        this.onDestroy = new Subject();
    }
    ngOnInit() {
        this.configureInput();
    }
    ngOnDestroy() {
        this.onDestroy.next();
    }
    configureInput() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const input = yield this.ionInput.getInputElement();
            const maskedInput = createTextMaskInputElement({
                inputElement: input,
                mask: this.mask,
                guide: false,
            });
            // masking when event is not generated
            maskedInput.update(input.value);
            this.ionInput.value = input.value;
            // masking when event is  generated
            this.ionInput.ionChange.pipe().subscribe((event) => {
                const { value } = event.detail;
                maskedInput.update(value);
                this.ionInput.value = input.value;
            });
        });
    }
}
