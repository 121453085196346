import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var DataKeys;
(function (DataKeys) {
    DataKeys["OFFSETTING_DETAILS"] = "offsettingDetails";
    DataKeys["PLANTING_DETAILS"] = "plantingDetails";
    DataKeys["PROJECT_DETAILS"] = "projectDetails";
    DataKeys["LOCATION_DETAILS"] = "locationDetails";
})(DataKeys || (DataKeys = {}));
export class DataService {
    constructor() {
        this.notificationCount = new BehaviorSubject(0);
        this.notificationQueryParams = new BehaviorSubject(null);
        this.deeplinkQueryParams = new BehaviorSubject(null);
        this.consentStatus = new BehaviorSubject(false);
        this.userDetails = new BehaviorSubject(null);
        this.consumptionBy = new BehaviorSubject(false);
        this.storageData = new BehaviorSubject(null);
        this.geoLocationData = new BehaviorSubject(null);
        this.currencyExchangeRates = new BehaviorSubject(null);
    }
    getNotificationCount() {
        return this.notificationCount.asObservable();
    }
    setNotificationCount(count) {
        this.notificationCount.next(count);
    }
    getNotificationQueryParams() {
        return this.notificationQueryParams.asObservable();
    }
    setNotificationQueryParams(qp) {
        this.notificationQueryParams.next(qp);
    }
    getDeeplinkQueryParams() {
        return this.deeplinkQueryParams.asObservable();
    }
    setDeeplinkQueryParams(qp) {
        this.deeplinkQueryParams.next(qp);
    }
    getConsentStatus() {
        return this.consentStatus.asObservable();
    }
    setConsentStatus(status) {
        this.consentStatus.next(status);
    }
    getUserDetails() {
        return this.userDetails.asObservable();
    }
    setUserDetails(data) {
        this.userDetails.next(data);
    }
    getConsumptionBy() {
        return this.consumptionBy.asObservable();
    }
    setConsumptionBy(value) {
        this.consumptionBy.next(value);
    }
    getStorageData() {
        return this.storageData.asObservable();
    }
    setStorageData(data) {
        this.storageData.next(Object.assign(this.storageData, data));
    }
    getGeoLocationData() {
        return this.geoLocationData.asObservable();
    }
    setGeoLocationData(data) {
        return this.geoLocationData.next(data);
    }
    getCurrencyExchangeRates() {
        return this.currencyExchangeRates.asObservable();
    }
    setCurrencyExchangeRates(currencyExchangeRates) {
        this.currencyExchangeRates.next(currencyExchangeRates);
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
