import { isDevMode } from '@angular/core';
import * as i0 from "@angular/core";
export class LoggerService {
    constructor() { }
    log(message, ...optionalParams) {
        // enableProdMode();
        if (isDevMode()) {
            console.log(message, ...optionalParams);
        }
    }
}
LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
