import * as i0 from "@angular/core";
export class StorageService {
    constructor() { }
    getAuthoken() {
        return new Promise((res) => {
            const t = this.getItem("authToken" /* AUTH_TOKEN */);
            t ? res(t) : res('');
        });
    }
    setAuthoken(authToken) {
        this.setItem("authToken" /* AUTH_TOKEN */, authToken);
    }
    getUID() {
        return new Promise((res, rej) => {
            const t = this.getItem("uids" /* UID */);
            t ? res(t) : rej({});
        });
    }
    setUID(uid) {
        this.setItem("uids" /* UID */, uid);
    }
    getMeterId() {
        return this.getItem("meterid_electricity" /* METER_ID */);
    }
    setMeterId(meterId) {
        this.setItem("meterid_electricity" /* METER_ID */, meterId);
    }
    getMPAN() {
        return this.getItem("mpan" /* MPAN */);
    }
    setMPAN(mpan) {
        this.setItem("mpan" /* MPAN */, mpan);
    }
    getSessionId() {
        return this.getItem("sessionId" /* SESSION_ID */);
    }
    setSessionId(sessionId) {
        this.setItem("sessionId" /* SESSION_ID */, sessionId);
    }
    getSignupStep() {
        return this.getItem("signupStep" /* SIGNUP_STEP */);
    }
    setSignupStep(signupStep) {
        this.setItem("signupStep" /* SIGNUP_STEP */, `${signupStep}`);
    }
    getItem(key) {
        return localStorage.getItem(key);
    }
    setItem(key, value) {
        localStorage.setItem(key, value);
    }
    removeItem(key) {
        localStorage.removeItem(key);
    }
    clear() {
        localStorage.clear();
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
