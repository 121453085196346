import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { MeterComponent } from '@app/components/meter/meter.component';
export class MpanNotFoundComponent {
    constructor(modalController, platform) {
        this.modalController = modalController;
        this.platform = platform;
        this.isVisible = false;
        this.close = new EventEmitter();
        this.platform.backButton.subscribeWithPriority(1000, () => {
            this.hide();
        });
    }
    ngOnInit() { }
    hide() {
        this.isVisible = false;
        this.close.emit(this.isVisible);
    }
    notifyMe() {
        this.hide();
    }
    openMpanModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: MeterComponent,
                cssClass: 'cst-modal-wrapper cst-modal-wrapper--mpan',
                componentProps: {
                    pan: 'MPAN',
                },
                backdropDismiss: false,
            });
            return yield modal.present();
        });
    }
}
