import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import {
//   SecureStorage,
//   SecureStorageObject,
// } from '@ionic-native/secure-storage/ngx';
import { Storage } from '@ionic/storage';
import { ApiService } from './api.service';
import { CancelHttpCallsService } from '@providers/cancel-http-calls.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
import * as i3 from "@angular/router";
import * as i4 from "./api.service";
import * as i5 from "./cancel-http-calls.service";
export class AuthService {
    constructor(http, 
    // public secureStorage: SecureStorage,
    storage, router, apiProvider, cancelHttpCallsService) {
        this.http = http;
        this.storage = storage;
        this.router = router;
        this.apiProvider = apiProvider;
        this.cancelHttpCallsService = cancelHttpCallsService;
    }
    setUid(uid) {
        return new Promise((resolve, reject) => {
            // sets user ID in local storage
            this.storage.set("uids" /* UID */, uid.uid);
            this.storage.set("uobj" /* UOBJ */, JSON.stringify(uid));
            // this.storage.set('auth-token', uid.access_token);
            resolve('done');
        });
    }
    logout() {
        this.logoutWithoutRedirect();
        setTimeout(() => this.router.navigate(['/home'], { replaceUrl: true }), 500);
    }
    logoutWithoutRedirect() {
        this.signOut();
        this.clearStorage();
    }
    signOut() {
        this.apiProvider
            .signOut()
            .then((res) => {
            this.apiProvider.createToast('Successfully Logout');
            this.cancelHttpCallsService.cancelPendingRequests();
        })
            .catch((err) => { });
    }
    clearStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const Email = yield this.storage.get("email" /* EMAIL */);
            const TabPage = yield this.storage.get("tabPage" /* TAB_PAGE */);
            const UserDefaultCurrency = localStorage.getItem('defaultCurrencyUser');
            yield this.storage.clear();
            if (Email) {
                this.storage.set("email" /* EMAIL */, Email);
            }
            this.storage.set("tabPage" /* TAB_PAGE */, TabPage);
            localStorage.setItem('defaultCurrencyUser', UserDefaultCurrency);
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.ApiService), i0.ɵɵinject(i5.CancelHttpCallsService)); }, token: AuthService, providedIn: "root" });
