import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ionic/storage";
export class VerifyIhdMacGuard {
    constructor(router, storage) {
        this.router = router;
        this.storage = storage;
    }
    canActivate(next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const contract = yield this.storage.get("contract" /* CONTRACT */);
            if (contract === 'true' || contract === true) {
                this.router.navigate(['/dashboard/account-overview']);
                return false;
            }
            return true;
        });
    }
}
VerifyIhdMacGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerifyIhdMacGuard_Factory() { return new VerifyIhdMacGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Storage)); }, token: VerifyIhdMacGuard, providedIn: "root" });
