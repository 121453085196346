import * as tslib_1 from "tslib";
// import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
export class Address2Guard {
    constructor(storage) {
        this.storage = storage;
    }
    canActivate(next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.storage.get("uids" /* UID */);
            const address = yield this.storage.get("address" /* ADDRESS */);
            if (uid && (address !== 'true' || address !== true)) {
                return true;
            }
            return false;
        });
    }
}
Address2Guard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Address2Guard_Factory() { return new Address2Guard(i0.ɵɵinject(i1.Storage)); }, token: Address2Guard, providedIn: "root" });
