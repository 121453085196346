import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
// enum PageNames {
//   BUDGET = 'Budget',
// }
const PageNames = {
    BUDGET: 'Budget',
};
export class RedirectionService {
    constructor(router) {
        this.router = router;
    }
    redirectTo(pageName, queryParams) {
        switch (pageName) {
            case PageNames.BUDGET:
                this.navigateTo('/dashboard/budget', queryParams);
                break;
            default:
                this.navigateTo('/dashboard/account-overview', queryParams);
        }
    }
    navigateTo(path, queryParams) {
        queryParams
            ? this.router.navigate([path], { queryParams })
            : this.router.navigate([path]);
    }
}
RedirectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectionService_Factory() { return new RedirectionService(i0.ɵɵinject(i1.Router)); }, token: RedirectionService, providedIn: "root" });
