import { RestService } from '@shared/services/rest.service';
import { ApiEndpoints } from '@app/config';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
export class AutomaticTariffService {
    constructor(restService) {
        this.restService = restService;
    }
    getAutomaticTarrif(meterId) {
        return this.restService.get(`${ApiEndpoints.automaticTariff(meterId)}`);
    }
    changeAutomaticTarrif(meterId, req) {
        return this.restService.put(`${ApiEndpoints.automaticTariff(meterId)}`, req);
    }
}
AutomaticTariffService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AutomaticTariffService_Factory() { return new AutomaticTariffService(i0.ɵɵinject(i1.RestService)); }, token: AutomaticTariffService, providedIn: "root" });
